import React, { useContext, useEffect } from 'react';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';
import ContentBlock from 'components/Content/ContentBlock';
import BrandCTA from 'components/BrandCta';
import ColorBlock from 'components/Content/ColorBlock';
import SourceEmitter from 'libs/emitter';
import clinicalDataHCPHeroMobile from '../../../assets/images/NS-HCP-Hero-ClincalData-mobile.png';

// import page style
import './clinical-data.scss';

// Images
import imgMeanConcentration from '../../../assets/images/vitaminb12-mean-concentration.png';
import imgSerumMean from '../../../assets/images/serumb12-mean.png';

// PDFs

const ClinicalDataPage = () => {
  let Subscription = null;
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.hcp;
  // const metaData = appConfigs?.metaData?.hcp;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    window.addEventListener('hashchange', triggerHashChange, false);
    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
    });
    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  // const brandNames = {
  //   NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
  //   BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
  //   NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  // };

  const pageTitle = 'NASCOBAL® | Clinical Data';
  const pageDescription = 'View the clinical data for NASCOBAL®';

  const renderPage = () => {
    return (
      <div id="nascobal-hcp-clinical-data">
        <ContentBlock id="clinical-data-block">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>Clinical Data</h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={clinicalDataHCPHeroMobile} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h2 className="strong">
                  See the impact of{' '}
                  <span
                    className="brandname-nascobal"
                    dangerouslySetInnerHTML={{
                      __html: brandNames.NASCOBAL,
                    }}
                  />{' '}
                  Nasal Spray on healthy vitamin B
                  <sub className="vitaminb12-sub">12</sub> levels
                </h2>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="flex-display">
                <ColorBlock gray>
                  <h2>
                    Clinically proven to have increased vitamin B<sub>12</sub>{' '}
                    levels from the first dose
                    <sup>1</sup>*
                  </h2>
                  <img
                    src={imgMeanConcentration}
                    alt="vitamin-b12-mean-concentration"
                  />
                  <ul>
                    <li>
                      Achieved mean peak serum vitamin B<sub>12</sub>{' '}
                      concentration within 1.5 hours<sup>1</sup>*
                    </li>
                    <li>
                      Reached mean peak concentration of 1021 pg/mL
                      <sup>1</sup>*
                    </li>
                    <li>
                      At <span className="orange">72 hours</span>, mean serum
                      vitamin B<sub>12</sub> level was increased by{' '}
                      <span className="orange">46%</span> above baseline
                      <sup>1</sup>*
                    </li>
                  </ul>
                  <p className="footnote asterik bottom-spacer">
                    In 21 healthy volunteers under fasting conditions, a single
                    500 mcg dose of{' '}
                    <span
                      className="brandname-nascobal"
                      dangerouslySetInnerHTML={{
                        __html: brandNames.NASCOBAL,
                      }}
                    />{' '}
                    was given and monitored for 3 days. Data are based on
                    baseline uncorrected serum vitamin B<sub>12</sub> levels.
                  </p>
                </ColorBlock>
              </Col>
              <Col xs={12} md={6}>
                <ColorBlock white>
                  <h2>
                    Intranasal administration has maintained healthy vitamin B
                    <sub>12</sub> levels<sup>2</sup>
                  </h2>
                  <img src={imgSerumMean} alt="serum-b12-mean" />
                  <ul>
                    <li>
                      Once-a-week intranasal dosing maintained higher serum
                      vitamin B<sub>12</sub> levels than 1 intramuscular vitamin
                      B<sub>12</sub> injection after 28 days<sup>2</sup>
                    </li>
                  </ul>
                  <p>
                    In 24 patients with a history of pernicious anemia, a
                    single-dose cyanocobalamin 100 mcg/mL intramuscular (IM)
                    injection was given and monitored for 28 days, followed by 4
                    once-weekly doses of intranasal (IN) vitamin B<sub>12</sub>{' '}
                    gel 500 mcg/0.1 mL. Mean baseline serum vitamin B
                    <sub>12</sub> prior to IN dosing = 351.4 pg/mL, and at day
                    28 = 480.7 pg/mL.
                    <sup>2</sup>
                  </p>
                  <p>
                    In a separate study, bioavailability of vitamin B
                    <sub>12</sub> nasal spray was 10% less than vitamin B
                    <sub>12</sub> nasal gel. Clinical significance is unknown.
                    <sup>3</sup>
                  </p>
                </ColorBlock>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/hcp/practice-resources/"
                  LinkCaption="DISCOVER PRACTICE RESOURCES"
                >
                  DISCOVER PRACTICE RESOURCES
                </BrandCTA>
              </Col>
              <Col xs={12} md={6}>
                <BrandCTA
                  href="/vitaminb12/hcp/how"
                  LinkCaption="SEE HOW TO USE NASCOBAL"
                >
                  SEE HOW TO USE NASCOBAL
                  <sup className="brand-reg-mark">&reg;</sup>
                </BrandCTA>
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="hcp-clinical-data">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default ClinicalDataPage;
